import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import axios from '@/axios'
import router from './router'
import 'vant/lib/index.css';
import 'lib-flexible/flexible'
import VueClipboard from 'vue-clipboard2'
import '../public/css/base.css'

import VueLuckyCanvas from "@lucky-canvas/vue";
Vue.use(VueLuckyCanvas)

Vue.use(VueClipboard)
Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
new Vue({
    router,
    render: h => h(App),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
}).$mount('#app')
