const routerConfig = {
    routes: [
        {
            path: '/',
            name: 'index',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/home/home')
            ,
            meta: {
                title: '首页',
                showTabbar: true

            }
        },
        {
            path: '/login',
            name: 'login',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/login/login')
            ,
            meta: {
                title: '登录',

            }
        },
        {
            path: '/member',
            name: 'member',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/member')
            ,
            meta: {
                title: '我的',
                showTabbar: true

            }
        },
        {
            path: '/member/wallet',
            name: 'wallet',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/wallet')
            ,
            meta: {
                title: '我的钱包',
                showTabbar: false

            }
        },
        {
            path: '/member/generate-hf-wallet',
            name: 'generateHfWallet',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/generate-hf-wallet')
            ,
            meta: {
                title: '汇付钱包开户',

            }
        },
        {
            path: '/member/generate-sd-wallet',
            name: 'generateSdWallet',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/generate-sd-wallet')
            ,
            meta: {
                title: '杉德钱包开户',

            }
        },
        {
            path: '/product',
            name: 'product_detail',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/goods/goods-info'),
            meta: {
                title: '详情',
            }
        },
        {
            path: '/mygoods-list',
            name: 'mygoods-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/mygoods/mygoods-list'),
            meta: {
                title: '我的藏品',
            }
        },
        {
            path: '/mygoods-info',
            name: 'mygoods-info',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/mygoods/mygoods-info'),
            meta: {
                title: '藏品详情',
            }
        },
        {
            path: '/mygoods-log',
            name: 'mygoods-log',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/mygoods/mygoods-log'),
            meta: {
                title: '打工日志',
            }
        },
        {
            path: '/mygoods-details',
            name: 'mygoods-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/mygoods/mygoods-details'),
            meta: {
                title: '藏品详情',
            }
        },
        {
            path: '/market',
            name: 'market',
            component: () => import(/* webpackChunkName: "about" */ '../pages/market/market-list'),
            meta: {
                title: '市场',
                showTabbar: true

            },
        },
        {
            path: '/market/goods',
            name: 'market_goods',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/market/goods-detail'),
            meta: {
                title: '市场',
            }
        },
        {
            path: '/order/info',
            name: 'orderInfo',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/order/order-info'),
            meta: {
                title: '订单详情',
            }
        },
        {
            path: '/notice',
            name: 'notice',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/notice/notice'),
            meta: {
                title: '公告',
                showTabbar: true
            }
        },
        {
            path: '/notice/notice-details',
            name: 'notice-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/notice/notice-details'),
            meta: {
                title: '公告详情',
                showTabbar: false
            }
        },
        {
            path: '/member/set-up',
            name: 'set-up',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/set-up')
            ,
            meta: {
                title: '设置',
                showTabbar: false

            }
        },
        {
            path: '/member/info',
            name: 'info',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/info')
            ,
            meta: {
                title: '个人信息',
                showTabbar: false

            }
        },
        {
            path: '/member/edit-password',
            name: 'edit-password',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/edit-password')
            ,
            meta: {
                title: '修改密码',
                showTabbar: false
            }
        },
        {
            path: '/member/edit-paypassword',
            name: 'edit-paypassword',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/edit-paypassword')
            ,
            meta: {
                title: '修改支付密码',
                showTabbar: false
            }
        },
        {
            path: '/member/edit-info',
            name: 'edit-info',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/edit-info')
            ,
            meta: {
                title: '修改信息',
                showTabbar: false

            }
        },
        {
            path: '/member/forgot-password',
            name: 'forgot-password',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/member/forgot-password')
            ,
            meta: {
                title: '忘记密码',
                showTabbar: false

            }
        },
        {
            path: '/invite',
            name: 'invite',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/invite/invite')
            ,
            meta: {
                title: '邀请好友',
                showTabbar: false
            }
        },
        {
            path: '/invite-list',
            name: 'invite-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/invite/invite-list')
            ,
            meta: {
                title: '邀请记录',
                showTabbar: false
            }
        },
        {
            path: '/ranking',
            name: 'ranking',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/invite/ranking')
            ,
            meta: {
                title: '邀请排行榜',
                showTabbar: false
            }
        },
        {
            path: '/register',
            name: 'register',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/register/register'),
            meta: {
                title: '注册账号',
                showTabbar: false

            }
        },
        {
            path: '/order-list',
            name: 'order-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/order/order-list')
            ,
            meta: {
                title: '订单列表',
                showTabbar: false
            }
        },
        {
            path: '/order-details',
            name: 'order-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/order/order-details')
            ,
            meta: {
                title: '订单详情',
                showTabbar: false
            }
        },
        {
            path: '/resale/order-list',
            name: 'resale-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/resale/order-list')
            ,
            meta: {
                title: '我的转售',
                showTabbar: false
            }
        },
        {
            path: '/resale/order-info',
            name: 'resale-info',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/resale/order-info')
            ,
            meta: {
                title: '转售藏品',
                showTabbar: false
            }
        },
        {
            path: '/resale/order-details',
            name: 'resale-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/resale/order-details')
            ,
            meta: {
                title: '转售订单详情',
                showTabbar: false
            }
        }, 
        {
            path: '/transfer/order-list',
            name: 'transfer-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/transfer/order-list')
            ,
            meta: {
                title: '我的转赠',
                showTabbar: false
            }
        },
        {
            path: '/transfer/order-details',
            name: 'transfer-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/transfer/order-details')
            ,
            meta: {
                title: '转赠订单详情',
                showTabbar: false
            }
        }, 
        {
            path: '/synthesis/list',
            name: 'synthesis-list',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/synthesis/list')
            ,
            meta: {
                title: '合成藏品活动',
                showTabbar: false
            }
        },
        {
            path: '/synthesis/details',
            name: 'synthesis-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/synthesis/details')
            ,
            meta: {
                title: '合成详情',
                showTabbar: false
            }
        }, 
        {
            path: '/synthesis/order-list',
            name: 'synthesis-order',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/synthesis/order-list')
            ,
            meta: {
                title: '合成记录',
                showTabbar: false
            }
        }, 
        {
            path: '/synthesis/order-details',
            name: 'synthesis-order-details',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/synthesis/order-details')
            ,
            meta: {
                title: '合成记录详情',
                showTabbar: false
            }
        }, 
        {
            path: '/lucyk/grid',
            name: 'grid',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/lucyk/grid')
            ,
            meta: {
                title: '幸运抽奖',
                showTabbar: false
            }
        },
        {
            path: '/login/agreement',
            name: 'agreement',
            component: () =>
                import(/* webpackChunkName: "about" */ '../pages/login/agreement')
            ,
            meta: {
                title: '协议',
                showTabbar: false
            }
        }, 
    ]
};

export default routerConfig;
