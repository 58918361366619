import axios from 'axios'
import router from '@/router'
import {
    Toast
} from 'vant'
import {getCacheUserToken} from '@/utils/CacheUtil'
axios.defaults.timeout = 20000;

axios.interceptors.request.use(function (config) {
    // if (config.headers.isLoading !== false) {
    //   // 如果配置了isLoading: false，则不显示loading
    // Toast.loading({
    //   message: '加载中...',
    //   forbidClick: true,
    //   overlay: false,
    //   duration: 0
    // });
    // }
    config.headers.Authorization = getCacheUserToken()
    // 这个请求对象必须return回去，不然请求不会继续发送
    return config
}, function (error) {
    // 返回请求错误信息的函数
    Toast.fail('网络请求异常')

    return Promise.reject(error)
})

axios.interceptors.response.use((res) => {

    //token 错误或者超时 重新登陆
    if(res.data.code === 4007){
        localStorage.clear()
        Toast('请登录')
        router.push({name:'login'})
    }
    return res
}, function (err) {
    // if (err.request) {
    //   Toast({
    //     message: '网络请求异常，请刷新重试',
    //     duration: 5000,
    //     forbidClick: true
    //   });
    // }
    if (err.response) {
        if (err.response.status != 200) {
            // Toast('网络请超时')
            // Toast.fail('失败文案');
            Toast({
                message: '网络请求异常，请刷新重试',
                duration: 5000,
                forbidClick: true
            });
        }
    }
    return Promise.reject(err)
})

export default axios
