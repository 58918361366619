
export const getCacheUserinfo = () => {
    let userinfo = localStorage.getItem('uinfo');
    if (userinfo) {
        userinfo = JSON.parse(userinfo);
        return userinfo;
    } else {
        return null;
    }
};

export const getCacheUserToken = () => {
    return localStorage.getItem("jtoken")
}

export const setCacheUserInfo = (userinfo) => {
    localStorage.setItem('uinfo', JSON.stringify(userinfo))
}

export const delCacheUserInfo = () => {
    localStorage.clear();
}